.JoiibmedIcon {
    height: 45px;
}
.header{
    padding: 20px 40px;
}
.hr{
    background: grey;
    width: 1px;
    position: absolute;
    height: 85px;
    top: -14px;
}
.home{
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    min-width: 650px !important;
}
.sidebar{
    background-color: white;
    border-bottom: none !important;
    border-top: none !important;
}
.left-sidebar{
    padding-left: 75px;
    max-width: 480px;
    min-width: 285px;
    width: 30%;
    /* display: block; */
}
.right-sidebar{
  width: 74%;
  padding-right: 20px;
}
.container{
  display: flex;
  padding: 0;
  /* padding-right: 20px; */
}
.react-calendar{
    font-size: 14px;
    border: none !important;
    height: 274px;
    font-size: 12px;
}

.appoinment-header{
    display: flex;
    justify-content: space-between;
}
.appoinment-count{
    height: 23px;
}

.card .card-body .message {
    padding-left: 80px;
    
    /* &::after {
      clear: both;
    } */
  }
  .card-body{
    display: flex;
    background: ghostwhite;
  }
  
  
  .card .card-body .actions {
    margin-top: 5px;
  }
  .bookings{
    display:flex;
    justify-content: space-between;
    width: 90%;
  }
  .rounded-circle{
      height: 35px;
      width: 35px;
  }
  .booking-time{
    font-weight: bold;
    font-size: 14px;
    text-align: center;
  }
  .call-button{
    align-items: center;
    display: flex;
  }
  .text-img{
    background: green;
    align-items: center;
    display: flex;
    justify-content: center;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
  }
  .join-button{
    font-size: 12px;
  }
  .header-date{
    font-size: 13px;
  }
  .MuiAvatar-root{
    width: 35px !important;
    height: 35px !important;
  }
  .loader{
    margin-top: 20%;
    text-align: center;
  }

  .MuiBackdrop-root{
    z-index: 100 !important;
    color: white !important;
  }

  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
    border-radius: 4px;
}
/* .button-menu-icon{
  cursor: pointer;
} */


  /* @media screen and (max-width:800px){

    * {
        background-color: red;
    }
    
    
    } */



    .MuiButton-containedPrimary{
      background-color: #3b6dba !important;
  }
  .MuiButton-outlinedPrimary{
      color: #3b6dba !important;
      border: 1px solid rgb(59,109,186) !important;
  }
  .right-sidebar-menu{
    display: none;
  }

  @media only screen and (max-width: 1060px){
    .left-sidebar{
      padding-left: 10px;
    }
  }
  @media only screen and (max-width: 780px){
    .right-sidebar-menu{
      display: block;
    }
    .left-sidebar.disp-none{
      display: none;
    }
    .left-sidebar.disp-block{
      display: block;
    }
    .right-sidebar{
      width: 100%;
    }
    
    .MuiSvgIcon-root.menu-icon{
      font-size: 30px !important;
    }
  }
  .recent-booking-listing{
    max-height: 600px;
    overflow: auto;
  }