
.right-sidebar-menu{
    display: none;
  }

  @media only screen and (max-width: 1060px){
    .left-sidebar{
      padding-left: 10px;
    }
  }
  @media only screen and (max-width: 780px){
    .right-sidebar-menu{
      display: block;
    }
    .left-sidebar.disp-none{
      display: none;
    }
    .left-sidebar.disp-block{
      display: block;
    }
    .right-sidebar{
      width: 100%;
    }
    
    .MuiSvgIcon-root.menu-icon{
      font-size: 30px !important;
    }
  }